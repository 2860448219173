import React, { useRef } from "react"
import { Link, navigate } from "gatsby"
import { Row, Col, Button, Card, Media, Modal } from 'react-bootstrap'
import Layout from "../components/layout"
import Header from "../components/header"
import MobileHeader from '../components/MobileHeader'
import SEO from "../components/seo"
import Container from 'react-bootstrap/Container'
import Footer from '../components/footer'
import CommonFlex from '../components/commonFlex'
import Hexagon from '../components/hexagon'
import Consult from '../components/consult'
import MoreApplicationScenarios from './../components/MoreApplicationScenarios'

import { bdstatistics, apiURL, mqStatistics, check } from '../components/census'
import { useStaticQuery, graphql } from "gatsby"

import './../components/media.css'
import './../assets/css/index.css'
import './../components/release.css'
import './../assets/css/aritcle.css'

// 引入此路径，才不会打包失败
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import './../assets/css/navigation.min.css'
import './../assets/css/pagination.min.css'
import './../assets/css/scrollbar.min.css'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

const dataMy = [
  {
    title: '辰安科技',
    desc: 'AIRIOT智慧系统搭建平台，为我们打造智慧化工园区平台提供了坚实的数字基座，提供了包含设备数据采集、边缘计算和处理、智能控制、设备建模和管理、可视化监控和控制、算法配置和应用、时序数据存储和管理，以及业务流程管理等方面的全面技术支持。',
    url: require('./../images/辰安科技.png'),
    peopleStyle: {
      width: '4.5rem',
      height: '1.375rem'
    },
    name: '杨先生',
    post: '项目经理',
    borderColor: '#fb4b51'
  },
  {
    title: '青岛汉缆',
    desc: `我们采用AIRIOT搭建的智慧工厂管理系统，更符合我们当前阶段的数字化路径和关键举措，可以推动业务、技术、组织和可持续发展能力的协同发展，切实带来生产经营与管理指标的改善，助力智造升级。`,
    url: require('./../images/青岛汉缆.png'),
    peopleStyle: {
      width: '1.6875rem',
      height: '2rem'
    },
    style: {
      width: '12.75rem',
      height: '3.125rem',
      maxWidth: '12.75rem'
    },
    name: '张总',
    post: '信息化负责人',
    borderColor: '#373E98'
  },
  {
    title: '万德高科',
    desc: `AIRIOT智慧能碳管理平台，能够实现相关数据的精细化、集中化、专业化管理，降低数据收集、填报、报告编制等成本，提高管理效率。有助于使我们在碳市场中处于有利位置，预防和降低政策风险冲击。`,
    // desc: `航天科技自主研发的物联网平台不但能帮助我们在智慧社区业务中解决各种物联网设备接入的问题，使我司现有产品成熟度更高以外，其强大的数据采集引擎可以帮助我们解决工控领域工厂数字化管理的问题，打造一流的智慧工厂，拓宽我们的业务领域，还能在城市智慧化改造和社会治理、乡村振兴领域发挥巨大的作用。`,
    url: require('./../images/万德高科.png'),
    peopleStyle: {
      width: '2.3125rem',
      height: '2.3125rem'
    },
    name: '刘先生',
    post: '技术总监',
    borderColor: '#6fd100',
    style: {
      width: '12.75rem',
      height: '3.125rem',
      maxWidth: '12.75rem'
    },
  },
  {
    title: '航天电工',
    desc: `AIRIOT提供的智慧系统搭建平台，为我们构建智慧工厂管理系统提供了坚实的数字基座。平台易上手、高效率、可灵活配置，实现了工厂的自动化和智能化生产，产品质量和稳定性也得到了很大提升。`,
    url: require('./../images/航天电工.png'),
    peopleStyle: {
      width: '2.3125rem',
      height: '2.3125rem'
    },
    style: {
      width: '12.75rem',
      height: '3.125rem',
      maxWidth: '12.75rem'
    },
    name: '周先生',
    post: '项目工程师',
    borderColor: '#d164fb'
  },
  {
    title: '羲和能慧',
    desc: `AIRIOT智慧系统搭建平台具有互联优化透明的特性且属性灵活，提供了工厂的综合管理水平，让生产高效、可行可控，促进企业健康稳定发展，帮助企业实现高效管理。`,
    // desc: `自使用了航天科技AIRIOT物联网平台之后呢，大大减少了我们在技术平台开发方向的投入，也完成了我们在人工智能的数据采集方向的一些痛点。未来希望我们能和航天科技AIRIOT平台还有技术人员能有更好的合作。`,
    url: '',//require('./../images/烟台国工.png'),
    peopleStyle: {
      width: '1.6875rem',
      height: '2rem'
    },
    name: '杨先生',
    post: '总经理',
    borderColor: '#373E98',
    style: {
      width: '12.75rem',
      height: '3.125rem',
      maxWidth: '12.75rem'
    }
  },
  {
    title: '重庆水投',
    desc: `AIRIOT智慧系统搭建平台是赋能工业和智慧领域数字化转型的技术基座，在数字技术等专业领域与重庆水投的融合，将从点到面赋能集团数字化转型升级，助推重庆水投集团综合实力实现持续提升。`,
    url: require('./../images/重庆水投.png'),
    peopleStyle: {
      width: '2.3125rem',
      height: '2.3125rem'
    },
    name: '王先生',
    post: '产品经理',
    borderColor: '#fab300',
    style: {
      width: '8.75rem',
      height: '3.125rem',
      maxWidth: '8.75rem'
    }
  },
  {
    title: '京环科技',
    desc: `AIRIOT是一款完全开放、易学易用、支持灵活改动和对接的智慧系统搭建平台，依托其构建的垃圾填埋场渗沥液处理平台，覆盖各业务环节，实现全区厂站的统筹监管和智能化运行。`,
    url: require('./../images/北京环卫.png'),
    peopleStyle: {
      width: '4.75rem',
      height: '1.125rem'
    },
    name: '祝博士',
    post: '总体事业部负责人',
    borderColor: '#8564fb',
    style: {
      width: '12.75rem',
      height: '3.125rem',
      maxWidth: '12.75rem'
    }
  },
  {
    title: '上海才匠',
    desc: `AIRIOT智慧系统搭建平台为离散制造业数字化转型提供了数字基座，实现了离散制造业生产管理的协同制造、过程追溯和信息共享，进一步推动制造业的数字化、智能化发展，为制造业在新的时代背景下带来更广阔的发展空间。`,
    url: require('./../images/才匠智能.jpg'),
    peopleStyle: {
      width: '1.6875rem',
      height: '2rem'
    },
    name: '李总',
    post: 'CEO',
    borderColor: '#373E98'
  },
  {
    title: '烟台国工智能',
    desc: `自使用了AIRIOT物联网平台之后呢，大大减少了我们在技术平台开发方向的投入，也完成了我们在人工智能的数据采集方向的一些痛点。未来希望我们能和AIRIOT平台还有技术人员能有更好的合作。`,
    // desc: `自使用了航天科技AIRIOT物联网平台之后呢，大大减少了我们在技术平台开发方向的投入，也完成了我们在人工智能的数据采集方向的一些痛点。未来希望我们能和航天科技AIRIOT平台还有技术人员能有更好的合作。`,
    url: require('./../images/烟台国工.png'),
    peopleStyle: {
      width: '1.6875rem',
      height: '2rem'
    },
    name: '柳总',
    post: 'CEO',
    borderColor: '#373E98'
  },
  {
    title: '北京易华录信息技术股份有限公司',
    desc: 'AIRIOT为易华录提供了战略性支持。AIRIOT平台作为物联网项目的入口级工具，以低代码的方式快速构建稳定可靠的物联网系统。在具体的项目中，为客户提供多样化部署方式，灵活的资产管理，强大的数据接入能力，便捷的组态以及完善的二次开发能力，为智慧城市建设提供了非常重要的基础性建设能力。',
    url: require('./../images/易华录.png'),
    peopleStyle: {
      width: '4.5rem',
      height: '1.375rem'
    },
    name: '高先生',
    post: '副主任',
    borderColor: '#fb4b51'
  },
  {
    title: '铯镨科技',
    desc: `通过以AIRIOT整个平台的集成，帮助铯镨平台解决了我们在平台工厂还有平台工厂软硬件的协同，包括智慧工艺软硬件的协同，推进了铯镨平台在整个建筑产业、工业互联网产业上下游当中的协同发展，扩展铯镨平台在建筑工业领域当中的整个技术发展。`,
    url: require('./../images/铯镨科技.png'),
    peopleStyle: {
      width: '2.3125rem',
      height: '2.3125rem'
    },
    name: '蒲总',
    post: 'CEO',
    borderColor: '#d164fb'
  }
]

const IndexPage = () => {
  const [hash, setHash] = React.useState(null)
  const [recomments, setRecomments] = React.useState([])
  const [activeIndex, setActiveIndex] = React.useState(1)
  const [swipers, setSwipers] = React.useState(null)
  const [agent, setAgent] = React.useState(true)
  const [visible, setVisible] = React.useState(false)
  const [initPerView, setPerView] = React.useState(4)
  const [row, setRow] = React.useState(false)
  // const [ data, setData ] = React.useState([])
  const swiperRef = useRef()
  const [width, setWidth] = React.useState(false)

  // const articles = useStaticQuery(
  //   graphql`
  //   query{
  //     allStrapiArticles (sort: {fields: created_at, order: DESC}, filter: {recomment: {eq: "recomment"}}) {
  //       edges {
  //         node {
  //           title,
  //           slug,
  //           recomment,
  //           articlesTag,
  //           created_at,
  //           content,
  //           image {
  //             childImageSharp {
  //               fixed(width: 1600, height: 800) {
  //                 base64
  //               }
  //             }
  //           },
  //           description
  //         }
  //       }
  //     }
  //   }`
  // )

  // const articlesData = articles?.allStrapiArticles?.edges

  // React.useEffect(() => {
  //   if (articlesData?.length) {
  //     const json = articlesData.filter(item => item?.node && item?.node?.recomment === 'recomment').map(item => item?.node)
  //     let rwJson = json?.length ? json.filter(item => item.recomment === 'recomment' && item.articlesTag == '软文').slice(0, 1) : []
  //     let gkkJson = json?.length ? json.filter(item => item.recomment === 'recomment' && item.articlesTag == '公开课').splice(0, 1) : []
  //     let dyJson = json?.length ? json.filter(item => item.recomment === 'recomment' && item.articlesTag == '答疑').splice(0, 1) : []
  //     let newJson = [...rwJson, ...gkkJson, ...dyJson] || []
  //     setRecomments(newJson)
  //   }
  // }, [JSON.stringify(articlesData)])

  const articles = useStaticQuery(
    graphql`
    {
      rw: allStrapiArticles(filter: { articlesTag: { eq: "软文" }, recomment: {eq: "recomment"}}, sort: { fields: created_at, order: DESC }, limit: 1) {
        edges {
          node {
            title
            slug
            recomment
            articlesTag
            created_at
            image {
              childImageSharp {
                fixed(width: 1600, height: 800) {
                  base64
                }
              }
            }
          }
        }
      }
      gkk: allStrapiArticles(filter: { articlesTag: { eq: "公开课" }, recomment: {eq: "recomment"}}, sort: { fields: created_at, order: DESC }, limit: 1) {
        edges {
          node {
            title
            slug
            recomment
            articlesTag
            created_at
            content
            image {
              childImageSharp {
                fixed(width: 1600, height: 800) {
                  base64
                }
              }
            }
          }
        }
      }
      dy: allStrapiArticles(filter: { articlesTag: { eq: "答疑" }, recomment: {eq: "recomment"}}, sort: { fields: created_at, order: DESC }, limit: 1) {
        edges {
          node {
            title
            slug
            recomment
            articlesTag
            created_at
            image {
              childImageSharp {
                fixed(width: 1600, height: 800) {
                  base64
                }
              }
            }
          }
        }
      }
    }
    `
  )
  
  const articlesData = [
    ...(articles.rw.edges.length ? [articles.rw.edges[0].node] : []),
    ...(articles.gkk.edges.length ? [articles.gkk.edges[0].node] : []),
    ...(articles.dy.edges.length ? [articles.dy.edges[0].node] : [])
  ]

  React.useEffect(() => {
    setRecomments(articlesData)
  }, [JSON.stringify(articlesData)])


  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
    let width = document.body.clientWidth

    if (!check()) {
      setPerView(1)
    } else {
      if (width <= 400) {
        setPerView(1)
      } else if (width <= 768 && width > 400) {
        setPerView(2)
      } else if (width <= 1100 && width > 768) {
        setPerView(3)
      } else if (width <= 1200 && width > 1100) {
        setPerView(4)
      } else if (width <= 1440 && width > 1200) {
        setPerView(3)
      } else if (width <= 1920 && width > 1440) {
        setPerView(4)
      } else {
        setPerView(5)
      }
    }
    // fetch(`${apiURL}/articles?recomment=recomment&_sort=created_at:DESC`, {
    //   headers: { 'content-type': 'application/json' },
    // })
    //   .then((res) => {
    //     if (res.status == 200) {
    //       return res.json()
    //     }
    //   })
    //   .then(json => {
    //   })
    //   .catch(err => console.log(err))

    // fetch(`${apiURL}/video-swiper`, {})
    //     .then((res) => {
    //       if (res.status == 200) { return res.json() }
    //     })
    //     .then(json => {
    //       json?.video && setVideoSwiper({ ...videoSwiper, data: json?.video })
    //     })
    //     .catch(err => console.log(err))  

    if (typeof window !== "undefined") {
      if (window.location.hash && window.location.hash.indexOf('#') > -1) {
        setHash(window.location.hash.substr(window.location.hash.indexOf('#') + 1))
      }

      let width = document.body.clientWidth
      if (width < 992) {
        setWidth(true)
      } else {
        setWidth(false)
      }
      if (width <= 1024) {
        setRow(true)
      }


      window.addEventListener('resize', (event) => {
        let width = document.body.clientWidth
        if (width <= 1024) {
          setRow(true)
        }

        if (width < 992) {
          setWidth(true)
        } else {
          setWidth(false)
        }


        if (width <= 400) {
          setPerView(1)
        } else if (width <= 768 && width > 400) {
          setPerView(2)
        } else if (width <= 1100 && width > 768) {
          setPerView(3)
        } else if (width <= 1200 && width > 1100) {
          setPerView(4)
        } else if (width <= 1440 && width > 1200) {
          setPerView(3)
        } else if (width <= 1920 && width > 1440) {
          setPerView(4)
        } else {
          setPerView(5)
        }
      })

      // const engineTopHeight = 72 //window.document.getElementsByClassName('engineTop')[0].clientHeight || 72
      // window.document.getElementsByClassName('home-containter-pref')[0].style.top= `-${(engineTopHeight + 4)/16}rem`
    }
    setAgent(check())

    //首页案例
    // fetch(`${apiURL}/application-scenarios?_sort=updated_at:DESC`, {
    //   headers: { 'content-type': 'application/json' },
    // })
    //   .then((res) => {
    //     if (res.status == 200) {
    //       return res.json()
    //     }
    //   })
    //   .then(json => {
    //     const jsonData = json.filter(item => item?.homePage).sort((a, b) => a.order - b.order)
    //     setData([ ...jsonData.map(item => {
    //       const bannerTitle = item.bannerTitle && item.bannerTitle.substring(0, item.bannerTitle.indexOf('案例'))
    //       return { ...item, bannerTitle }
    //     }) ])
    //   })
    //   .catch(err => console.log(err))

  }, [])

  React.useEffect(() => {
    if (hash) {
      // 跳转到指定位置
      let product_media = document.getElementsByClassName('engineHead');
      window.scrollTo({
        top: product_media[0].offsetTop - 150
      })
    }
  }, [hash])

  // const getCookie = (name) => {
  //   var strcookie = document.cookie;//获取cookie字符串
  //   var arrcookie = strcookie.split("; ");//分割
  //   //遍历匹配
  //   for (var i = 0; i < arrcookie.length; i++) {
  //     var arr = arrcookie[i].split("=");
  //     if (arr[0] == name) {
  //       return arr[1];
  //     }
  //   }
  //   return "";
  // }

  // const downPlat = () => {
  //   let cookieData = getCookie('data')
  //   if (cookieData && JSON.parse(cookieData)) {
  //     let contact_name = JSON.parse(cookieData).contact_name
  //     navigate("/try")
  //   } else {
  //     navigate("/try")
  //   }
  // }

  const homeSwiperRef = React.useRef()

  const getHeader = (width) => {
    return width ? <MobileHeader /> : <Header />
  }


  {/* <video className="home-video" ref={videoRef} 
              style={{ display: agent ? 'block' : 'none', position: 'absolute', width: '100%', objectFit: 'fill' }} autoPlay="autoplay" 
              muted={true} loop src={require('./../assets/homevideo.webm')}
            ></video> */}
  {/* {
              videoBg ? <div className="home-video-mask" style={{ display: agent ? 'block' : 'none', position: 'absolute', width: '100%', background: 'rgba(0,0,0,.5)', zIndex: 100 }}></div> : 
              <div className="home-video-image" style={{ position: 'absolute', width: '100%', height: '100%', backgroundImage: `url(${require("./../images/123.png")})`, zIndex: 99,
              backgroundColor: 'rgba(113, 112, 229, 0.1)' }}></div>
            } */}

  console.log(recomments, '=====')
  return (
    <div className="home-product-sp">
      <div className="home-index home-nav-fix">
        {/* <Header /> */}
        {getHeader(width)}
      </div>
      <Swiper
        // loop={true}
        // autoplay={{ delay: 5000 }}
        ref={homeSwiperRef}
        // modules={[Navigation, Autoplay, Pagination]}
        // slidesPerView={1}
        // pagination={{ clickable: true }}
        // onSwiper={(swiper) => {
        //   swiper.$el[0].addEventListener('mouseover', function() { swiper.autoplay.stop()});
        //   swiper.$el[0].addEventListener('mouseleave',()=>swiper.autoplay.start());
        // }}
        className="home-swiper"
      >
        <SwiperSlide>
          {/* <div className="tg-page">
             <div className="home-bg home-index home-tg" style={{ background: `url(${require("./../images/主页背景.png")})`, backgroundSize: '100% 100%', height: '43.75rem' }}>
               <Layout typePage="home">
                 <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
                 <div className="engine-plat-common">
                   <div className="plat-jumbotron">
                     < img src={require('./../images/主页文字.png')} className="plat-tg-title" />
                     <div className="plat-tg-desc">
                       <span className="tg-zy">AIRIOT平台工具及案例模板库任意用</span>
                     </div>
                     <a href=" " style={{ color: '#fff', marginLeft: '7.5rem' }}><button style={{
                       marginTop: '2rem', padding: '0.625rem 3.125rem', color: '#fff', background: 'transparent', border: '1px solid #fff', borderRadius: '2rem',
                       fontSize: '2rem'
                     }}>了解详情</button>
                     </a >
                   </div>  
                   <div className="jumbotron-right-common" >
                     <img className={`jumbotron-img`} src={require('./../images/主页主图.png')}></img>
                   </div>
                 </div>
               </Layout>
             </div>
           </div> */}
          <span className="product-price">
            <span className="product-price engine-home-4 tg-index-page">
              <div className="home-bg home-index home-tg home-pref" style={{ position: 'relative' }}>
                <Layout typePage="home">
                  <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
                  <div className="producePrice-plat">
                    <div className="plat-jumbotron">
                      <div className="">
                        <div className="jumbotron-title-common" style={{ fontSize: agent ? '3.25rem' : '2rem', color: 'rgba(18, 20, 28, 0.9)' }}>
                          人人都能用的<span className="zhxt">智慧系统</span>搭建平台
                        </div>
                        <div className="jumbotron-index-desc jumbotron-desc-common jumbotron-desc-common-home" style={{ marginTop: 56 }}>高效的智慧项目交付引擎</div>
                        <div className="jumbotron-index-desc jumbotron-desc-common jumbotron-desc-common-home">自主研发自主可控 海量驱动高稳定性</div>
                        <div className="jumbotron-index-desc jumbotron-desc-common jumbotron-desc-common-home">AI驱动丰富算法模型 三维可视化无缝对接数字孪生</div>
                        <div className="jumbotron-index-desc jumbotron-desc-common jumbotron-desc-common-home">打造全行业智能化物联系统</div>
                        <div>
                          <Button variant="primary" className="home-pref-btn" onClick={() => navigate("/engine4")}>立即体验</Button>
                          <Link to="https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve" target="_blank">
                            <Button variant="primary" className="home-pref-btn" style={{ marginLeft: 56 }}>申请试用</Button>
                          </Link>
                        </div>


                      </div>
                    </div>

                  </div>
                </Layout>
              </div>
            </span>
          </span>
        </SwiperSlide>
        {/* <SwiperSlide>
          <span className="product-price engine-home-4">
            <div className="home-bg home-index" style={{ position: 'relative', background: `url(${require("./../images/engine-home-4.png")}) no-repeat`, backgroundSize: 'cover' }}>
                <Layout typePage="home">
                    <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
                    <div className="producePrice-plat">
                        <div className="plat-jumbotron">
                            <div className="jumbotron-left">
                                <div className="jumbotron-title-common">
                                  <img src={require('./../images/airiot.png')} className="engine-img-left"/>
                                  <span>
                                    <span>4.0版本</span>
                                    <span className="engine-jumbotron-span">最新功能</span>
                                  </span>
                                </div>
                                <div className="jumbotron-desc-common">化繁为简 创新无限</div>
                                <Button variant="primary" className="engine-button-product" onClick={() => navigate("/engine4")}>点击了解详情</Button>
                            </div>
                        </div>
                        <div className="jumbotron-right">
                            <img src={require('./../images/单帧2.png')}></img>
                        </div>
                    </div>
                </Layout>
            </div>
          </span>
        </SwiperSlide>
    
        <SwiperSlide>
          <div className="home-bg home-index" style={{ position: 'relative', background: `url(${require("./../images/123.png")}) no-repeat`, backgroundSize: 'cover' }}>
            <div className="home-video-mask" style={{ display: 'block', position: 'absolute', width: '100%', background: 'rgba(0,0,0,.5)', zIndex: 100 }}></div> 
            <div className="home-video-image" style={{ position: 'absolute', width: '100%', height: '100%', background: `url(${require("./../images/123.png")}) no-repeat`, zIndex: 99,
              backgroundColor: 'rgba(113, 112, 229, 0.1)' }}></div>
            <Layout typePage="home">
              <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
              <div className="home-title container" style={{ position: 'absolute', top: 0, left: 0, width: '100%', textAlign: 'center', width: '100%', height: '100%', zIndex: 200 }}>
                <div >
                  <div className="plat-jumbotron plat-normal-title home-plat-jumbotron plat-jumbotron-top" style={{ marginTop: '4.5rem' }}>
                    <div className="plat-title plat-title-f">AIRIOT<span className="plat-title plat-title-s">物联网平台</span></div>

                    <div className="plat-desc" style={{ padding: '0 3.75rem', margin: '3rem auto 0', maxWidth: '76rem' }}>高效打造稳定可靠的行业物联网产品，灵活拓展，百万级大规模设备数据采集能力</div>
                    <div className="plat-desc" style={{ padding: '0 3.75rem', margin: '1rem auto 0', maxWidth: '76rem' }}>服务800+物联网项目，平均缩短项目实施周期70%</div>
                    <p>
                      <Button variant="primary" className="plat-start" onClick={()=> navigate('/video4')} style={{ marginRight: '2rem' }}>产品视频</Button>
                      <Button variant="primary" className="plat-start" onClick={() => downPlat()}>申请试用</Button>
                    </p>
                    <p className="plat-desc-secord">
                      <span>央企出品</span>
                      <span>大项目验证</span>
                      <span>长期保障</span>
                    </p>
                  </div>
               
                </div>
              </div>
            </Layout>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/price" className="product-price">
            <div className="home-bg home-index" style={{ position: 'relative', background: `url(${require("./../images/322.png")}) no-repeat`, backgroundSize: 'cover' }}>
                <Layout typePage="home">
                    <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
                    <div className="producePrice-plat">
                        <div className="plat-jumbotron">
                            <div className="jumbotron-left">
                                <div className="jumbotron-title jumbotron-title-top">AIRIOT智慧物联优选套餐</div>
                                <div className="price-jumb-desc" style={{ marginTop: '120px' }}><span className="jumbotron-desc-active">九大行业</span> 模板（任选其一）+  <span className="jumbotron-desc-active">通用版</span> 物联网平台</div>
                                <div className="jumbotron-margin price-jumb-desc">限时6折，低至1800元 <span className="jumbotron-secrod-active">可申请试用</span></div>
                                <div className="jumb-btn-sq">
                                    <Button><Link to="/price">点击了解详情</Link></Button>
                                </div>
                            </div>
                        </div>
                        <div className="jumbotron-right">
                            <img src={require('./../images/00.png')}></img>
                        </div>
                    </div>
                </Layout>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/articles"  className="product-price">
          <div className="home-bg home-index" style={{ position: 'relative', background: `url(${require("./../images/WechatIMG217.png")}) no-repeat`, backgroundSize: 'cover' }}>
                <Layout typePage="home">
                    <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
                    <div className="producePrice-plat">
                        <div className="plat-jumbotron" style={{ marginTop: '11.5rem' }}>
                          <div className="plat-title plat-title-f" style={{ textShadow: 'none', letterSpacing: 0, fontSize: '5.375rem' }}>10+行业应用场景案例</div>
                          <div className="price-jumb-desc">
                            <div style={{ marginTop: '2.4rem' }}>{ indSpans.map((item, index)=> (<span style={ index == 3 ? { display: 'inline-block', paddingLeft: '1.5rem', paddingRight: '1.5rem' } : { display: 'inline-block', paddingLeft: index == 0 ? '0.2rem' : '1.5rem', paddingRight: '1.5rem', borderRight: '2px solid #fff' }}>{item}</span>)) }</div>
                            <div style={{ marginTop: '2rem' }}>{ indSpans1.map((item, index)=> (<span style={ index == 3 ? { display: 'inline-block', paddingLeft: '1.5rem', paddingRight: '1.5rem' } : { display: 'inline-block', paddingLeft: index == 0 ? '0.2rem' : '1.5rem', paddingRight: '1.5rem', borderRight: '2px solid #fff' }}>{item}</span>)) }</div>
                          </div>
                        </div>
                    </div>
                </Layout>
            </div>
          </Link>
        </SwiperSlide> */}
      </Swiper>
      {/* <Link to="/price">
        <img className="active-banner-img" src={require('./../images/activeBanner.png')} />
      </Link> */}
      {/* <Row xs={1} lg={3} md={3} className="banner_row">
          {
            activeData.map(item => {
              return (
                <Col>
                  <Link to={item.link} target={item?.target} className="bg-link-top" style={{ textDecoration: 'none' }}>
                    <Card className="banner_card">
                      <Card.Title>{item.first_title}<span className="banner_arrow">></span></Card.Title>
                      <Card.Title className="banner_title">{item.title}</Card.Title>
                      <div className="banner_desc">{item.desc}</div>
                      <div className="banner_desc">{item.secrod_desc}</div>
                    </Card>
                  </Link>
                </Col>
              )
            })
          }
        </Row> */}
      <Consult />

      <div className="home-containter-pref" id="home-containter-pref">

        {/* <CommonFlex animate="animate1" title="应用场景" desc="AIRIOT物联网平台为全行业提供智能化物联系统的技术底座搭建，满足80%以上的物联项目需求，成功服务1000+项目，平均缩短项目实施周期70%，节省50%以上人力成本。"></CommonFlex>
          <Row xs={1} lg={3} md={2} className="scenario-case scenario-case-index">
            {
              scenarioCase.map(item => {
                return (
                  <Col style={{ background: `url(${item.bg_image}) no-repeat`, backgroundSize: '100% 100%' }}>
                    <div className="bg_after"></div>
                    <Link className="bg-link-top" style={{ textDecoration: 'none' }}>
                      <Card className="base-detail scenario_card">
                        <img src={item.title_image} alt="" />
                        <em></em>
                        <Card.Title>{item.title}</Card.Title>
                        <Link to={`/articlesDetail?id=${item.id}`} style={{ display: 'inline-block', width: '10.375rem' }}><button className="indexMore" >查看更多+</button></Link>
                      </Card>
                    </Link>
                  </Col>
                )
              })
            }
          </Row> */}

        {/* <Container style={{ padding: 0 }}> */}
        <MoreApplicationScenarios />
        {/* <div className="engineTopBox" style={{ height: !check() ? '25.6875rem' : '48.6875rem' }}>
            <div className="engineBox">
              <div className="engineTop">
                <Row>
                  <Col md={10} lg={10}>
                    <Row>
                    {
                      data.map((item, index) => {
                        return <Col><div className="gap-sm" onClick={() => swipers.slideToLoop(index, 1000, false)}>
                          <div className={activeIndex == (index + 1) ? "font-body font-body-active" : "font-body"}>{item?.bannerTitle}</div>
                        </div></Col>
                      })
                    }
                     { row ?  <Col>
                      <Link className="gap-sm" to="/articles">
                        <div className="font-body" >
                          更多案例
                          <img className="font-svg" src={require('./../images/arrowright.svg')} />
                        </div>
                      </Link>
                    </Col> : false }
                    </Row>
                  </Col>
                  <div className="line-engine" style={{ height: '80%', borderWidth: 1, borderRightColor: 'rgba(22,18,46,.3)' }}></div>
                  { row ? null : <Col className="viewMore-col" md={2} lg={2}>
                    <Link className="gap-sm viewMore" to="/articles">
                      <div className="font-body" >
                        更多案例
                        <img className="font-svg" src={require('./../images/arrowright.svg')} />
                      </div>
                    </Link>
                  </Col> }
                </Row>
              </div>
              <div className="engineRight" style={{ width: '100%' }}>
                <Swiper
                  loop={true}
                  autoplay={{
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                  }}
                  effect='fade'
                  lazy={{ loadPrevNext: true }}
                  ref={swiperRef}
                  modules={[Navigation, Autoplay, Pagination]}
                  slidesPerView={1}
                  //  navigation
                  //  pagination={{ clickable: true }}
                  onSwiper={(swiper) => setSwipers(swiper)}
                  onSlideChange={(swiper) => { if (swiper.activeIndex == data?.length + 1) { setActiveIndex(1) } else { setActiveIndex(swiper.activeIndex) } }}
                  className="new_custom engine-swiper partner-swiper"
                >
                  {
                    data.map((item, index) => {
                      return (
                        <SwiperSlide>
                          <div className="link-img" key={item.bannerTitle} style={{ position: 'relative', width: '100%', height: !check() ? '20.6875rem' : '48.6875rem' }}>
                            <Link to={`/articlesDetail?id=${item?.id}`}><img src={`${apiURL}${item.HomeTabImage.url}`} style={{ width: '100%', height: '100%' }} /></Link>
                            <Link to={`/articlesDetail?id=${item?.id}`}>
                              <Button style={{ position: 'absolute', top: '1rem', right: '2rem' }}>查看详情</Button>
                            </Link> 
                          </div>
                        </SwiperSlide>
                      )
                    })
                  }
                </Swiper>
              </div>
            </div>
          </div> */}
        {/* </Container> */}


        <div className="ecology-swiper-box ecology-swiper-box-home" style={{ marginTop: '5.625rem' }}>
          <ul className="ecology-ul">
            <li className="ecology-li">
              <img src={require('./../images/WechatIMG603.jpeg')} />
              <img src={require('./../images/WechatIMG603.jpeg')} />
            </li>
            <li className="ecology_cho_L"></li>
            <li className="ecology_cho_R"></li>
          </ul>
        </div>

        <Container className="content-box" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <CommonFlex style={{ marginTop: 80 }} animate="animate1" title="视频解锁AIRIOT4.0最新版本功能" desc="全新升级五大核心能力引擎，功能更全面，操作更简单。 满足数采数控、可视化组态、数据分析、业务流程管理等物联应用场景需求。同时支持二次开发，面向所有用户免费开放数据接口。"></CommonFlex>
          <video poster={require('./../images/单帧10001.png')} style={{ width: '100%', height: '100%' }}
            src='/strapi/uploads/1_5e14d994e8.mp4' controls autoPlay="autoplay" muted={true} loop
          >
          </video>
          {/* <div className="jumb-btn-sq" style={{ width: '100%', height: '100%', textAlign: 'center' }}>
        <Button style={{ fontSize: '1.5rem' }} variant="primary" 
          onClick={() => navigate("/engine4")}>点击获取AIRIOT4.0产品详情</Button>
      </div> */}
          {/* <div style={{ display: 'flex', width: '100%' }}>
        <div className="engineLeft">
          <div className="engineHead" name="engine">五大引擎</div>
          {
            engineData.map((item, index) => {
              return <Link className="engineRow" to={`/engine?name=${item.title}`} onClick={() => {
                swipers.slideToLoop(index, 1000, false); 
                }}>
                <div className="engineNum" style={activeIndex == (index + 1) ? { color: '#303030', background: `-webkit-linear-gradient(top, #98CDF4 0%, #5681EF 30%, #F495E5 60%, #F1C53E 100%)` } : { color: '#d5d6d9' }}>
                  <div className="content">
                    <img style={{ width: '20px', height: '20px' }} src={activeIndex == (index + 1) ? require(`./../images/active${index + 1}.png`) : require(`./../images/num${index + 1}.png`)} alt="" />
                  </div></div>
                <div className="engineTitle" style={activeIndex == (index + 1) ? { color: '#303030', fontWeight: 600, boxShadow: '0 10px 30px 0 #E4E5F2 ' } : { color: '#555d67', fontWeight: 400 }}>{item.title}</div>
              </Link>
            })
          }
          <Button className="engineBtn"><Link to="https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve">立即试用</Link></Button>
        </div>
        <div className="engineRight">
          <Swiper
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false
            }}
            ref={swiperRef}
            modules={[Navigation, Autoplay, Pagination]}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            onSwiper={(swiper) => setSwipers(swiper)}
            onSlideChange={(swiper) => { if (swiper.activeIndex == 6) { setActiveIndex(1) } else { setActiveIndex(swiper.activeIndex) } }}
            className="new_custom engine-swiper partner-swiper"
          >
            {
              engineData.map((item, index) => {
                return (
                  <SwiperSlide>
                    <div className="link-img" key={item.title} style={{ width: '100%' }}>
                      <Link to={`/engine?name=${item.title}`}><img src={item.url} style={{ width: '100%', height: '100%' }} /></Link>
                    </div>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
          <div className="desc">
            {
              engineData.map((item, index) => {
                return <div className="engineRow" style={index + 1 == activeIndex ? { display: 'block' } : { display: 'none' }}>
                  <div className="swiper-title">{item.title}</div>
                  <div className="swiper-desc">{item.desc}</div>
                </div>
              })
            }
          </div>
        </div>
      </div> */}
        </Container>

        <Container className="content-box" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <CommonFlex animate="animate1" title="平台优势" desc="海量驱动库秒级接入，无缝对接三维画面和数字孪生，平台预制项目模板快速落地项目，AI和算法让智能创造无限可能。"></CommonFlex>
          <Hexagon />
        </Container>
        <Container className="content-box" style={{ paddingLeft: 0, paddingRight: 0 }}>

          {/* <Row xs={1} lg={4} md={2} className="messageData-case">
            {
              messageData.map(item => {
                return (
                  <Col style={{ background: `url(${item.bg_image}) no-repeat`, backgroundSize: '100% 100%' }}>
                    <Card className="messageCard">
                      <Row>
                        <Col sm={4} className="message-col-left col">
                          <div className="title-first-bor">
                            <div>{item?.title}</div>
                          </div>
                        </Col>
                        <Col sm={8} className="message-col-right col"><div>{item?.desc}</div></Col>  
                      </Row>
                    </Card>
                  </Col>
                )
              })
            }
          </Row> */}

          {/* <Row xs={1} lg={4} md={2} className="messageData-case">
            {
              messageData.map((item, index) => {
                return (
                  <Col>
                    <Card className="messageCard-hx">
                      <Media className="industry-media-box" key={index}>
                        <Media.Body>
                          <div className="industry">
                            <div className="industry-title">{item?.title}</div>
                          </div>
                          <div className="industry-desc">{item.desc}</div>
                        </Media.Body>
                      </Media>
                    </Card>
                  </Col>
                )
              })
            }
          </Row> */}

          {/* <div className="common-customer">
            <CommonFlex animate="animate3" title="他们说“.......”" desc="AIRIOT服务于工业物联网和智慧领域各行业获得用户认可"></CommonFlex> */}
          {/* <Row xl={2} md={2} xs={1} className="customer-con">
              {
                dataMy.map((item, index) => (
                  <Col key={index}>
                    <Card className="card-group">
                      <Card.Body>
                        <Card.Title>
                          <span className="dataMy-img" style={item.peopleStyle}></span>
                          <span className="card-group-title">{item.title}</span>
                        </Card.Title>
                        <div className="marks-box">
                          <div className="marks"></div>
                          <div className="card-group-desc" dangerouslySetInnerHTML={{ __html: item.desc }}></div>
                        </div>
                        <Media>
                          <Media.Body>
                            <div className="customer-name">{item.name}</div>
                            <div className="customer-post">{item.post}</div>
                          </Media.Body>
                        </Media>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              }
            </Row> */}
          {/* <div className="articles-swiper video-swiper">
              {
                videoSwiper?.data?.length ? <Swiper
                  style={{ width: '100%' }}
                  className="articles-swipers"
                  loop={videoSwiper?.data?.length >=3 ? true : false}
                  // autoplay={{ delay: 3000 }}
                  ref={videoSwiperRef}
                  modules={[Navigation, Pagination]}
                  navigation
                  slidesPerView={'auto'}
                  centeredSlides={true}//居中
                  loopedSlides={5}
                  watchSlidesProgress={true}
                  onSwiper={(swiper) => {
                  }}
                  onProgress = { 
                    (progress) => {
                      let slides = progress?.slides
                      if(slides?.length >= 3) {
                        for (let i = 0; i < slides.length; i++) {
                          let slide = slides.eq(i);
                          let slideProgress = slides[i].progress;
                          let modify = 1;
                          if (Math.abs(slideProgress) > 1) {
                            modify = (Math.abs(slideProgress) - 1) * 0.6 + 1;
                          }
                          let translate = slideProgress * modify * 260;
                          let scale = 1 - Math.abs(slideProgress) / 5;
                          let zIndex = 99 - Math.abs(Math.round(10 * slideProgress))
                          let x = translate
                          slide.transform('translateX(' + x + 'px) scale(' + scale + ')')
                          slide.css('zIndex', zIndex)
                          slide.css('opacity', 1)
                          if (Math.abs(slideProgress) >= 3) {
                            slide.css('opacity', 0)
                          }
                        }
                      }
                    } 
                  }
                  onSetTransition = { 
                    transition => {
                      let slides = transition?.slides
                      if(slides?.length >=3) {
                        for (let i = 0; i < slides.length; i++) {
                          let slide = slides.eq(i)
                          slide.transition(transition);
                        }
                      }
                    }
                  }
                >
                { videoSwiper?.data?.length ? videoSwiper.data.map((item, index) => {
                    return (<SwiperSlide key={item?.id}>
                      <div onClick={() => {
                          setVideoSwiper({ ...videoSwiper, videoVisible: true, video: { poster: `${apiURL}${item?.poster?.url}`, url: `${apiURL}${item?.url?.url}` } })
                        }} style={{ cursor: 'pointer', position: 'relative', width: '100%', height: '100%' }}>
                        <img src={`${apiURL}${item?.poster?.url}`} style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', zIndex: 9 }}/> 
                        <img style={{ position: 'absolute', left: 0, top:0, right: 0, bottom: 0, margin: 'auto', zIndex: 10, width: '4.5rem', height: '4.5rem' }} src={require('./../images/WechatIMG226.png')} />
                      </div>
                    </SwiperSlide>)
                  }) : null
                }
                </Swiper>  : null
              }
            </div> */}
          {/* </div> */}
          {/* <video controls autoplay="autoplay" muted={true} style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', 
                zIndex: 8 }} id={`video-${index}`}
                poster={`${apiURL}${item?.poster?.url}`} preload="metadata" src={`${apiURL}${item?.url?.url}`}/> */}
        </Container>
        <Container><CommonFlex animate="animate1" title="用户评价" desc=""></CommonFlex></Container>
        <div className="customer-bg">
          <div className="customer-con">
            {
              dataMy.length ?
                <Swiper
                  modules={[Navigation]}
                  slidesPerView={initPerView}
                  centeredSlides={true}
                  spaceBetween={50}
                  slidesOffsetBefore={40}
                  lazy={{
                    loadPrevNext: true,
                    loadPrevNextAmount: 3,
                  }}
                  loop
                  navigation
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log('slide change')}
                >
                  {
                    dataMy.length ? dataMy.map(item => {
                      return (<SwiperSlide key={item?.id}>
                        <div className="card-group">
                          <div className="card-title-flex">
                            {
                              item?.url ? <>
                                <img className="my-logo" style={item?.style ? item?.style : {}} src={item?.url} />
                                <img className="my-comma" src={require('./../images/shape.webp')} />
                              </> : <>
                                <span style={item?.style ? item?.style : {}}>{item?.title}</span>
                                <img className="my-comma" src={require('./../images/shape.webp')} />
                              </>
                            }
                          </div>
                          <div className="card-group-desc" dangerouslySetInnerHTML={{ __html: item.desc }}></div>
                          <Media>
                            <Media.Body style={{ borderLeft: `6px solid ${item?.borderColor}` }}>
                              <div className="customer-name">{item.name}</div>
                              <div className="customer-post">{item.post}</div>
                            </Media.Body>
                          </Media>
                        </div>
                      </SwiperSlide>)
                    }) : null
                  }
                </Swiper> : null
            }
          </div>
        </div>
        {/* <div className="hy-bg">
            <Container className="content-box" style={{ paddingLeft: 0, paddingRight: 0 }}>
              <CommonFlex animate="animate2" title="各行各业的会心之选" desc="AIRIOT可以便捷的搭建高度适配的物联网平台，保障您的系统安全可靠"></CommonFlex>
              <Trade></Trade>
            </Container>
          </div> */}
        <Container className="content-box" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <CommonFlex animate="animate1" title="推荐浏览" desc=""></CommonFlex>
          <Row xs={1} lg={3} md={3}>
            {
              recomments?.length ? recomments.map(item => {
                return (
                  <Col className="recomments_base" style={{ marginBottom: '1rem' }}>
                    <Link to={item.articlesTag == '公开课' ? `${item.content}` : `/article/${item.slug}`} style={{ textDecoration: 'none' }} target={item.articlesTag == '公开课' ? `_black` : '_self'}>
                      <Card className="base-detail recomments_card">
                        {
                          item?.image?.childImageSharp?.fixed?.base64 ?
                            <img src={item.image.childImageSharp.fixed.base64} /> : null
                        }
                        <Card.Body>
                          <Card.Title>{item.title}</Card.Title>
                          <Card.Text>{item.description}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                )
              }) : null
            }
          </Row>

          {/* <CommonFlex animate="animate4" title="强大的功能" desc="平台功能强大且延展性高，支持多语言灵活扩展"></CommonFlex>
          <Row className="function-reg" xs={1} lg={4} md={2}>
            {
              data.map((item, index) => (
                <Col className="base-function" key={index}>
                  <Card className="base-detail">
                    <Card.Body>
                      <Card.Title>
                        <div className="card-cirle"></div>
                        <div className="card-desc">{item.title}</div>
                      </Card.Title>
                      <div className="base-box">
                        <Link to={`/product${item.href}`}>
                          <img className="base-img-bg" src={item.url} alt="" />
                          <Card.Img className="base-img" variant="top" src={item.img} />
                        </Link>
                      </div>
                      <Link to={`/product${item.href}`}>
                        <Button variant="primary" className="check-detail">
                          <span className="check-title">查看详情</span>
                          <span className="check-go">GO</span>
                        </Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            }
          </Row> */}


          {/* <CommonFlex animate="animate5" title="启航AIRIOT的物联之程" desc="<span class='card-text-active'>零成本体验AIRIOT，</span>详细的文档与视频物料做引导、支撑"></CommonFlex>
          <div className="start-air" style={{ marginBottom: '5rem' }}>
            <img src={require('./../images/left-top.png')} className="left-zs" />
            <Common style="30.75rem" shadowFlag={true}></Common>
          </div> */}

          <Modal className="video-modal-dialog" bsSize="lg" show={visible} onHide={() => setVisible(false)}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <video poster={require('./../images/video-poster.png')} style={{ width: '100%', height: '100%' }}
                src="/strapi/uploads/AIRIOT_2c9bd6b6ba.mp4" controls autoPlay="autoplay" muted={true} loop
              >
              </video>
            </Modal.Body>
          </Modal>

          {/* <Modal className="video-modal-dialog" bsSize="lg" show={videoSwiper.videoVisible} onHide={() => setVideoSwiper({ ...videoSwiper, videoVisible: false })}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <video poster={videoSwiper?.video?.poster} style={{ width: '100%', height: '100%' }}
                src={videoSwiper?.video?.url} controls autoPlay="autoplay" muted={true} loop
              >
              </video>
            </Modal.Body>
          </Modal> */}
        </Container>
        <div className="tl-banner">
          <div className="tl-banner-title">找对伙伴轻松一半，马上开启AIRIOT物联之旅</div>
          <Link to="https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve" target="_blank"><Button>开始试用</Button></Link>
        </div>

      </div>
      {/* <Banner></Banner> */}
      <Footer></Footer>
    </div>

  )
}

export default IndexPage
